export default {
  heading: 'Playfair Display, serif',
  body: 'Raleway, sans-serif',
  monospace: 'Menlo, monospace',
  display: '',
  display2: '',
  googleFonts: ['Playfair Display:400,500,600,700,800,900', 'Raleway:100,200,300,400,500,600,700,800,900']
  // customFamilies: [''],
  // customUrls: [''],
}
