export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      padding: '0rem',
      backgroundColor: 'secondary',
      position: 'static'
    },
    '.containerScrolled': {
      backgroundColor: 'secondary',
      padding: '0rem'
    },

    '.logoLocationContainer': {
      position: ['static', 'static', 'static', 'static'],
      margin: '0 auto 0 0',
      '.image': {
        filter: 'unset',
        maxHeight: '100px'
      }
    },

    '.hamburger': {
      borderRadius: '100px',
      padding: ['0.8rem 0.4rem', '0.75rem'],
      // backgroundColor: 'black',
      minWidth: '40px'
    },

    '.smallNavMenu': {
      '> div': {
        borderLeft: '1px solid lightgrey'
      },
      '.navItem': {
        padding: '0rem 1rem',
        a: {
          color: 'light',
          fontFamily: 'body',
          fontWeight: 'bold',
          letterSpacing: '0px',
          ':hover': {
            color: 'grey'
          }
        }
      }
    },

    '.navMenuLogo': {
      '.image': {
        maxHeight: '150px'
      },
      marginBottom: '1rem'
    },

    '.navMenu': {
      width: ['90%', '75%', '50%', ' 35%'],
      transform: 'unset',
      right: '0rem'
    },
    '.navMenuOpen': {
      width: ['90%', '75%', '50%', ' 35%'],
      backgroundColor: 'secondary',
      alignItems: 'flex-start',
      '.seperatorLine': {
        display: 'none'
      },
      '.navItem': {
        width: '100%',
        borderBottom: '1px solid lightgrey',
        a: {
          display: 'flex',
          padding: ['', '', '', '1rem'],
          fontSize: ['1.1rem', '1.25rem'],
          transition: 'all ease-in-out 0.25s',
          color: 'light',
          fontFamily: 'body',
          fontWeight: 'bold',
          letterSpacing: '0px',
          ':hover': {
            color: 'grey'
          }
        }
      }
    },

    '.navBlock': {
      right: '0rem',
      top: '-200vh',
      backgroundColor: 'rgb(70 70 70 / 86%)'
    },
    '.navBlockOpen': {
      backgroundColor: 'rgb(70 70 70 / 86%)'
    },

    '.phoneSocialContainer': {
      marginTop: '1rem',
      alignItems: 'flex-start',
      padding: '0rem 1.5rem',
      '.smallNavHeading ': {
        textAlign: 'left',
        fontFamily: 'body',
        width: '100%'
      },
      '.phoneContainer, .socialIconsContainer, .phoneContainer a': {
        alignItems: 'flex-start',
        justifyContent: 'flex-start'
      },
      a: {
        color: 'light',
        fontSize: '1rem'
      },
      svg: {
        width: '25px',
        height: '25px',
        path: {
          fill: 'light'
        }
      }
    }
  },

  footer: {
    backgroundColor: 'secondary',
    color: 'white',
    '.sectionHeading': {
      fontFamily: 'body'
    },
    '.footerSectionHeading': {
      color: 'primary',
      borderBottom: '1px solid',
      borderColor: 'primary'
    },
    '.gonationLogo svg': {
      fill: 'white'
    },
    '.multiButtonContainer': {
      flexDirection: ['', '', '', 'column'],
      a: {
        width: ['', '', '', '100%'],
        ':hover': {
          opacity: '0.7',
          backgroundColor: 'transparent'
        }
      }
    }
  },

  ctaWidget: {
    a: {
      borderRadius: ['', '', '100px']
    }
  },
  loadingPage: {
    backgroundColor: 'secondary'
  },

  // ? ====================
  // ? ===  reuseables  ===
  // ? ====================

  sideBySide1: {
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },
  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    '.content': {
      order: ['', '', '2']
    }
  },

  title: {
    borderLeft: 'none',
    paddingLeft: '0rem',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontSize: ['2rem', '', '3rem'],
    fontFamily: 'body',
    marginBottom: '1rem',
    textAlign: 'left'
  },
  subtitle: {
    color: 'primary',
    fontSize: ['1.25rem', '1.5rem', '2rem'],
    fontWeight: '500',
    borderBottom: '1px solid',
    marginBottom: '1rem',
    textAlign: 'left',
    width: 'fit-content'
  },

  text: {},

  sponsorshipButton: {
    backgroundColor: '#e9f7fb',
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['1.5rem', '1.75rem', '2rem']
    },
    button: {
      variant: 'buttons.primary'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageSponsorshipButton: {
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['1.5rem', '1.75rem', '2rem']
    },
    '#scriptWidget': {
      display: 'none'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageShout: {
    padding: ['0.5rem', '1rem'],
    backgroundColor: '#263544',
    flexDirection: ['row', 'row', 'row'],

    // shoutContainer
    '.content': {
      // left: ['0rem', '', '2rem'],
      width: ['65%', '75%', '75%', '75%'],
      padding: ['', '', '1rem 2rem'],
      backgroundColor: '#263544',
      color: 'light'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['1.5rem', '1.75rem', '2rem']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text',
      maxWidth: 'unset',
      fontSize: ['1.25rem', '1.5rem'],
      marginBottom: '0rem'
    },
    '.date': {
      order: '4',
      margin: '1rem 0rem 0rem'
    },
    '.shoutCTA': {
      variant: 'buttons.primary'
    },
    '.imageContainerBlock': {
      width: ['35%', '25%', '25%', '25%'],
      img: {
        objectFit: 'cover'
      }
    }
  },

  titleBlock: {
    padding: ['4rem 1rem', '6rem 1rem'],
    // background: 'unset',
    height: '90vh',
    backgroundAttachment: ['unset', 'unset', 'unset', 'unset'],
    '.section': {
      justifyContent: 'flex-start'
    },
    '.title': {
      fontFamily: 'body',
      textTransform: 'uppercase',
      fontSize: '7rem',
      fontWeight: 'bolder',
      background: 'linear-gradient(180deg, rgba(26,71,128,1) 0%, rgba(0,28,84,1) 100%)',
      backgroundClip: 'text',
      WebkitBackgroundClip: 'text',
      color: 'transparent',
      letterSpacing: '-2px',
      lineHeight: '0.8',
      margin: '0rem'
    },
    '.subtitle': {
      fontFamily: 'body',
      textTransform: 'uppercase',
      fontWeight: 'bolder',
      fontSize: '5rem',
      color: 'rgba(0,28,84,1)',
      order: '2',
      letterSpacing: '-2px',
      lineHeight: '0.8',
      margin: '0rem'
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },
  homepageK9: {
    variant: 'customVariants.sideBySide2'
  },
  homepageSponsorship: {
    variant: 'customVariants.sideBySide1'
  },
  homepagePresented: {
    variant: 'customVariants.sideBySide2',
    justifyContent: 'center',
    // marginTop: '3rem',
    padding: ['4rem 1rem', '', '10rem 0rem'],
    backgroundColor: '#eafbff',
    '.content': {
      display: 'none'
    },
    '.lazyload-wrapper': {
      width: ['90%', '50%', '30%'],
      margin: '0 auto'
    }
  },

  homepageHero: {
    justifyContent: 'flex-start',
    height: ['', '', '90vh'],
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['1.5rem', '', '2rem']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      lineHeight: ['1.25', '1.5', '1.75']
    },
    '.hero_content_container': {
      margin: '0rem',
      padding: ['1rem', '1rem', '', '1rem'],
      bottom: '0',
      border: 'none',
      width: '100%',
      '.ctaButton': {
        variant: 'buttons.primary',
        alignSelf: 'flex-start'
      }
    },
    '.muteToggle': {
      right: '2rem'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      variant: 'customVariants.title',
      textAlign: ['left', '', 'center']
    }
  },

  // ? ========================
  // ? =====  Menu page  ======
  // ? ========================

  menu: {
    '#buyNowButton': {
      variant: 'buttons.primary',
      margin: ' 0rem 0.5rem 0.5rem',
      fontSize: ['1rem', '1rem', '1rem', '1rem'],
      display: 'flex',
      width: 'fit-content'
    },
    '.dropdownContainer': {
      display: 'none'
    },

    '.backToMenuBtn': {
      variant: 'buttons.primary',
      marginBottom: '2rem'
    },

    '.menuContainer': {
      paddingBottom: '1rem'
    },

    // ===  cells ==
    '.cellImageContainer': {
      overflow: 'hidden',
      img: {
        transition: 'all ease-in-out 1s'
        // transformOrigin: 'center',
      },
      ':hover img': {
        transform: 'translate(-50%,-50%) scale(1.2)'
      }
    },
    '.menuCell, .cellImage': {
      borderRadius: '0px'
    },

    // ===  sections ==
    '.menuSectionTitle': {
      margin: '0rem 2rem 2rem',
      padding: '1rem',
      borderTop: '1px solid',
      borderBottom: '1px solid',
      borderColor: 'primary',
      color: 'primary',
      textTransform: 'uppercase'
    },
    '.menuSectionDescription': {
      margin: '0rem 2rem 2rem'
    },

    // ===  items ==
    '.menuItemContentContainer': {
      margin: '0rem'
    },

    '.menuItemDescription': {
      opacity: '0.8'
    },

    '.allInContainerWrapper > .allInContainer': {
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      '.menuContainer': {
        width: '100%'
      },
      '> .allInContainer': {
        width: ['100%', '', 'calc(50% - 2rem)'],
        backgroundColor: '#e1f1f5',
        borderRadius: '10px',
        margin: '1rem',
        padding: '1rem',
        '.menuItemsContainer': {
          '.menuItemContainer': {
            width: '100%'
          }
        },
        '.menuSectionTitle': {
          textAlign: 'left',
          fontSize: '1.5rem',
          fontFamily: 'body',

          color: 'primary',
          margin: '0rem 0rem 1rem'
        },
        '.menuSectionDescription': {
          textAlign: 'left',
          margin: ['0rem 0.5rem 1rem', '', '0rem 1.5rem 1rem'],
          fontWeight: 'bold',
          color: 'red'
        },

        '.menuItemName': {
          fontSize: ['1.1rem', '1.2rem'],
          margin: '0rem',
          display: 'list-item'
        }
      }
    }
  },

  k9Menu: {
    variant: 'customVariants.menu',
    '.menuSectionDescription': {
      color: 'black !important'
    }
  },

  // ? ========================
  // ? ====  testimonials page  ====
  // ? ========================

  menuCTAS: {
    '.ctaLink': {
      variant: 'buttons.primary',
      borderRadius: '0px',
      margin: '0 auto'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {},

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.eventItemImage': {
      minHeight: 'unset'
    },
    '.eventDays, .eventDate': {
      color: 'black'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    backgroundColor: '#e7eef8',
    '.title': {
      variant: 'customVariants.title',
      textAlign: ['center', '', 'center']
    },
    '.subtitle': {
      fontSize: ['1rem', '', '', '1.25rem'],
      margin: '1rem 0rem',
      opacity: '0.8'
    },
    'form input, form textarea': {
      border: 'none',
      borderBottom: '1px solid grey',
      borderRadius: '0px'
    },
    'form .submitBtn': {
      variant: 'buttons.primary'
    }
  },

  locationMap: {
    order: '3',
    h3: {
      textTransform: 'uppercase',
      fontSize: ['1.25rem', '', '', '1.75rem']
    }
  }
}
